<template>
  <overlay
    :show="loading"
    :opacity="opacityLoading"
    class-name="p-card-form"
  >
    <div
      id="adesao-nacional-register"
      class="content-wrapper"
    >
      <page-header
        screen-name="Cadastrar adesão nacional"
        :link-items="linkItems"
      />
      <main class="card p-2">
        <section class="p-2 bg-custom-blue rounded-top shadow">
          <b-row class="titles">
            <b-col>
              <b-row>
                <b-col>
                  <h2 class="text-light">
                    Grupo: {{ groupCompany.grupo_descricao }}
                  </h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h4 class="text-light">
                    Operador: {{ groupCompany.operador_descricao }}
                  </h4>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="text-right lh-4 pr-5">
              <span class="text-light">
                Doses distribuídas:
                <strong class="text-light font-medium-3"> {{ distribuicao.totalDoses.totalGrupo }} | </strong>
              </span>
              <span class="text-light">
                Doses disponíveis:
                <strong
                  v-if="groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo < 0"
                  class="text-danger font-medium-3"
                >
                  {{ groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo }}
                </strong>
                <strong
                  v-else
                  class="text-light font-medium-3"
                >{{ groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo }}</strong>
              </span>
            </b-col>
          </b-row>
        </section>
        <FormDistribuicaoEmpresasEnderecos
          :empresas="groupCompany.empresas"
          :vacinas="groupCompany.estimativa"
          action="register"
          @setLoading="setLoading"
        />
      </main>
    </div>
  </overlay>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import FormDistribuicaoEmpresasEnderecos from './Forms/FormDistribuicaoEmpresasEnderecos.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import {
  confirmOkActionTriangleWarn,
  warningInfoMessage,
  warningMessage,
} from '@/libs/sweetalerts'

import {
  GetChooseCampaignAdesaoNacional,
  GetChooseCompanyAdesaoNacional,
  GetChooseOperadorAdesaoNacional,
  SetAddressCompanyAdesaoNacional,
  GetDistribuicaoDosesAdesaoNacional,
  SetAdesoesEnderecoEmpresa,
} from '@/views/custom-pages/adesao-nacional/helpers'

import { getEstimativaCampanhaNacionalGroup, getCompaniesAddressesGroup } from '@/views/custom-pages/adesao-nacional/requests'

export default {
  title: 'Cadastrar adesão nacional',

  components: {
    Overlay,
    PageHeader,
    BRow,
    BCol,
    FormDistribuicaoEmpresasEnderecos,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Adesão Nacional',
          active: true,
          routeName: 'listar-adesao-nacional',
        },
        {
          name: 'Aderir Campanha',
          active: true,
          routeName: 'choose-campaign-nacional',
        },
        {
          name: 'Novo',
          active: true,
        },
      ],
      groupCompany: {
        empresas: [],
        estimativa: null,
      },
      loading: true,
      distribuicao: {
        totalDoses: {
          totalGrupo: 0,
        },
      },

      opacityLoading: '1',
    }
  },

  beforeMount() {
    SetAddressCompanyAdesaoNacional(null)
    SetAdesoesEnderecoEmpresa(null)
  },

  mounted() {
    if (!GetChooseCompanyAdesaoNacional()) {
      this.loading = true

      this.redirectToMainPage()

      return false
    }

    this.opacityLoading = '0.86'

    this.openModalAddressInfoNotChanged()

    return this.index()
  },

  methods: {
    index() {
      this.groupCompany = {
        id_grupo: GetChooseCompanyAdesaoNacional().id_grupo,
        grupo_descricao: GetChooseCompanyAdesaoNacional().descricao,
        id_operador: GetChooseOperadorAdesaoNacional().id_grupo_operador,
        operador_descricao: GetChooseOperadorAdesaoNacional().sigla_operador,
        id_campanha: GetChooseCampaignAdesaoNacional().id_campanha,
        campanha_descricao: GetChooseCampaignAdesaoNacional().campanha_descricao,
        totalDosesDisponiveis: 0,
      }

      this.distribuicao = GetDistribuicaoDosesAdesaoNacional()

      this.prepareFormAdesaoNacional()
    },

    async prepareFormAdesaoNacional() {
      this.loading = true

      const idCampanha = GetChooseCampaignAdesaoNacional().id_campanha
      const idGrupoOperador = GetChooseOperadorAdesaoNacional().id_grupo_operador

      await getEstimativaCampanhaNacionalGroup({ idCampanha, idGrupoOperador })
        .then(response => {
          this.groupCompany.estimativa = response.data
        })
        .catch(error => {
          this.handleError(error.response)
        })

      const params = { idGrupoOperador }

      await getCompaniesAddressesGroup(params)
        .then(response => {
          this.groupCompany.empresas = response.data.empresa
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.groupCompany.estimativa.forEach(estimativa => {
        this.groupCompany.totalDosesDisponiveis += Number(estimativa.saldo)
      })

      SetAddressCompanyAdesaoNacional(this.groupCompany.empresas)

      this.loading = false
    },

    setLoading(loading) {
      this.loading = loading
    },

    redirectToMainPage() {
      this.$router.replace({ name: 'listar-adesao-nacional' })
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve a adesão.',
          'Entendido',
        )

        return
      }

      warningMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },

    openModalAddressInfoNotChanged() {
      confirmOkActionTriangleWarn(
        'Atenção!',
        'Lembre-se de conferir os endereços e unidades operacionais vinculadas a cada uma das empresas presentes nesta adesão nacional antes de cadastrá-la. Uma vez que a adesão for cadastrada, ela manterá as informações que estavam presentes no cadastro da empresa naquele momento.',
        'Entendido',
      )
    },
  },
}
</script>

<style lang="scss">
#adesao-nacional-register {
 .shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
 }
 .icon-location {
    max-width: 50px;
  }
  .content-distribuicao {
      .card-header {
        background: #e5f0f9;
        border-left: 10px solid #2772C0;
        border-radius: 0;
      }
  }
  .lh-4 {
    line-height: 4rem;
  }
}
</style>
